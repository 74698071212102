import Vue from 'vue';
import VueRouter from 'vue-router'

Vue.use(VueRouter);

const Dashboard = () => import('../views/Dashboard');
const Video = () => import('../views/Video');
const PlansPricing = () => import('../views/PlansPricing');
const Whitepaper = () => import('../views/Whitepaper');

const routes = [
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
    },
    {
        path: '/video',
        name: 'Video',
        component: Video,
    },
    {
        path: '/plans-pricing',
        name: 'PlansPricing',
        component: PlansPricing,
    },
    {
        path: '/white-paper',
        name: 'Whitepaper',
        component: Whitepaper,
    }
];

const router = new VueRouter({
    mode: 'history',
    routes
});

export default router;
